import React from "react";

import * as Mustache from "@customer-ui/components/Mustache";

interface TextWithVariablesProps {
  value: string;
  className?: string;
  fallback?: string | React.ReactNode;
}

export const useTextWithVariables = ({
  value,
  fallback,
}: TextWithVariablesProps): React.ReactNode => {
  return (
    <Mustache.Content
      htmlTemplate={value as unknown as Mustache.SafeString}
      fallback={fallback}
    />
  );
};

const TextWithVariables: React.FC<TextWithVariablesProps> = ({
  value,
  className,
  fallback,
}) => {
  return (
    <Mustache.Content
      htmlTemplate={value as unknown as Mustache.SafeString}
      className={className}
      fallback={fallback}
    />
  );
};

export default TextWithVariables;
